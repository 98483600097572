<template>
  <div class="container-fluid">
    <table class="table">
      <thead>
        <tr>
          <th colspan="9">
            <h2>
              <button v-if="!actionplus" @click="actionplus = true"><i class="fa fa-plus"></i></button><button
                v-if="actionplus" @click="actionplus = false"><i class="fa fa-minus"></i></button> Actions à mener
              <span v-if="menuAdmin"><button @click="add()"><i class="fa fa-plus-square"></i></button></span>
            </h2>
          </th>
        </tr>
      </thead>

      <thead v-if="actionplus">
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Action</th>
          <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
          <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
          <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
          <th scope="col"><i class="fa fa-chevron-circle-right"></i> Fonction</th>
          <th scope="col"><i class="fa fa-chevron-circle-up"></i> Enc/Elv</th>
          <th scope="col"><i class="fa fa-calendar"></i> Echéance</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
          <th scope="col"><i class="fa fa-folder-open-o"></i> En Cours</th>
          <th scope="col"><i class="fa fa-camera"></i> Photo</th>
        </tr>
      </thead>

      <tbody v-if="actionplus">
        <tr :key="action.id" v-for="action in isActionNotArchived"
          :class="action.limite == 1 && action.solde == 0 ? 'limite' : ''">
          <td>{{ action.dt | sdt }}</td>
          <td>{{ action.Sujet }}</td>
          <td>{{ action.nomfeu }}</td>
          <td>
            <span v-if="action.strcode != null"><img :src="svg(action.strcode)" /></span>
          </td>
          <td>{{ action.nomregulateur }}</td>
          <td>{{ action.nomfonction }}</td>
          <td>{{ action.encelv }}</td>
          <td>
            <span v-if="action.solde == 0">{{ action.dateecheance | sdt }}</span>
            <span v-if="action.solde != 0" class="barre">{{ action.dateecheance | sdt }}</span>
          </td>
          <td>
            <span v-if="menuAdmin"><a href="#" @click="edit(action.id)"><i class="fa fa-edit"></i></a></span>
          </td>
          <td>
            <span v-if="action.solde == 0"><i class="fa fa-folder-open-o"></i></span>
            <span v-if="action.solde != 0"><i class="fa fa-folder"></i></span>
          </td>
          <td>
            <span v-if="action.nbphoto > 0" @click="edit(action.id)">
              <i class="fa fa-camera"> <span class="badge badge-pill badge-primary">{{ action.nbphoto }}</span></i>
            </span>
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="9">
            <h2>
              <button v-if="!actionarchivedplus" @click="actionarchivedplus = true"><i
                  class="fa fa-plus"></i></button><button v-if="actionarchivedplus" @click="actionarchivedplus = false"><i
                  class="fa fa-minus"></i></button> Actions archivées
            </h2>
          </th>
        </tr>
      </thead>

      <thead v-if="actionarchivedplus">
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Action</th>
          <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
          <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
          <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
          <th scope="col"><i class="fa fa-chevron-circle-right"></i> Fonction</th>
          <th scope="col"><i class="fa fa-chevron-circle-up"></i> Enc/Elv</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
          <th scope="col"><i class="fa fa-folder-open-o"></i> En Cours</th>
          <th scope="col"><i class="fa fa-camera"></i> Photo</th>
        </tr>
      </thead>

      <tbody v-if="actionarchivedplus">
        <tr :key="action.id" v-for="action in isActionArchived">
          <td>{{ action.dt | sdt }}</td>
          <td>{{ action.Sujet }}</td>
          <td>{{ action.nomfeu }}</td>
          <td>
            <span v-if="action.strcode != null"><img :src="svg(action.strcode)" /></span>
          </td>
          <td>{{ action.nomregulateur }}</td>
          <td>{{ action.nomfonction }}</td>
          <td>{{ action.encelv }}</td>
          <td>
            <span v-if="menuAdmin"><a href="#" @click="edit(action.id)"><i class="fa fa-edit"></i></a></span>
          </td>
          <td>
            <span v-if="action.solde == 0"><i class="fa fa-folder-open-o"></i></span><span v-if="action.solde != 0"><i
                class="fa fa-folder"></i></span>
          </td>
          <td>
            <span v-if="action.nbphoto > 0" @click="edit(action.id)">
              <i class="fa fa-camera"> <span class="badge badge-pill badge-primary">{{ action.nbphoto }}</span></i>
            </span>
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="9">
            <h2>
              <button v-if="!interplus" @click="interplus = true"><i class="fa fa-plus"></i></button><button
                v-if="interplus" @click="interplus = false"><i class="fa fa-minus"></i></button> Interventions réalisées
            </h2>
          </th>
        </tr>
      </thead>

      <thead v-if="interplus">
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Intervention</th>
          <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
          <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
          <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
          <th scope="col"><i class="fa fa-chevron-circle-right"></i> Fonction</th>
          <th scope="col"><i class="fa fa-chevron-circle-up"></i> Enc/Elv</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
          <th scope="col"><i class="fa fa-camera"></i> Photo</th>
        </tr>
      </thead>

      <tbody v-if="interplus">
        <tr :key="intervention.id" v-for="intervention in isInterNotArchived">
          <td>{{ intervention.dt | sdt }}</td>
          <td>{{ intervention.Sujet }}</td>
          <td>{{ intervention.nomfeu }}</td>
          <td>
            <span v-if="intervention.strcode != null"><img :src="svg(intervention.strcode)" /></span>
          </td>
          <td>{{ intervention.nomregulateur }}</td>
          <td>{{ intervention.nomfonction }}</td>
          <td>{{ intervention.encelv }}</td>
          <td>
            <span v-if="menuAdmin"><a href="#" @click="edit2(intervention.id)"><i class="fa fa-edit"></i></a></span>
          </td>
          <td>
            <span v-if="intervention.nbphoto > 0" @click="edit2(intervention.id)">
              <i class="fa fa-camera"> <span class="badge badge-pill badge-primary">{{ intervention.nbphoto }}</span></i>
            </span>
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="9">
            <h2><button v-if="!interarchivedplus" @click="interarchivedplus = true"><i
                  class="fa fa-plus"></i></button><button v-if="interarchivedplus" @click="interarchivedplus = false"><i
                  class="fa fa-minus"></i></button> Interventions archivées</h2>
          </th>
        </tr>
      </thead>

      <thead v-if="interarchivedplus">
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Intervention</th>
          <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
          <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
          <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
          <th scope="col"><i class="fa fa-chevron-circle-right"></i> Fonction</th>
          <th scope="col"><i class="fa fa-chevron-circle-up"></i> Enc/Elv</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
          <th scope="col"><i class="fa fa-camera"></i> Photo</th>
        </tr>
      </thead>

      <tbody v-if="interarchivedplus">
        <tr :key="intervention.id" v-for="intervention in isInterArchived">
          <td>{{ intervention.dt | sdt }}</td>
          <td>{{ intervention.Sujet }}</td>
          <td>{{ intervention.nomfeu }}</td>
          <td>
            <span v-if="intervention.strcode != null"><img :src="svg(intervention.strcode)" /></span>
          </td>
          <td>{{ intervention.nomregulateur }}</td>
          <td>{{ intervention.nomfonction }}</td>
          <td>{{ intervention.encelv }}</td>
          <td>
            <span v-if="menuAdmin"><a href="#" @click="edit2(intervention.id)"><i class="fa fa-edit"></i></a></span>
          </td>
          <td>
            <span v-if="intervention.nbphoto > 0" @click="edit2(intervention.id)">
              <i class="fa fa-camera"> <span class="badge badge-pill badge-primary">{{ intervention.nbphoto }}</span></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br /><br />
  </div>
</template>

<script>
import store from "../store";
import { getPrefix, selectApi } from "../lib/api.js";
import { getPath } from "../lib/svg.js";

export default {
  name: "Action",
  store: store,
  data() {
    return {
      counta: 0,
      actions: [],
      counti: 0,
      interventions: [],
      actionplus: true,
      actionarchivedplus: false,
      interplus: true,
      interarchivedplus: false
    };
  },
  computed: {
    menuAdmin: function () {
      return (store.state.typeUser == 2)
    },
    isActionArchived: function () {
      return this.actions.filter(i => i.archive === 1)
    },
    isActionNotArchived: function () {
      return this.actions.filter(i => i.archive !== 1)
    },
    isInterArchived: function () {
      return this.interventions.filter(i => i.archive === 1)
    },
    isInterNotArchived: function () {
      return this.interventions.filter(i => i.archive !== 1)
    }
  },
  filters: {
    sdt: function (dt) {
      if (!dt) return "";
      return (
        dt.substring(8, 10) +
        "/" +
        dt.substring(5, 7) +
        "/" +
        dt.substring(0, 4)
      );
    },
  },
  methods: {
    add() {
      this.$router.push({ path: "/addaction/0/0/0" });
    },
    edit(id) {
      this.$router.push({ path: "/addaction/" + id + "/0/0" });
    },
    edit2(id) {
      this.$router.push({ path: "/addinter/" + id + "/0/0" });
    },
    svg(code) {
      let path = getPath(code, 0, false);
      return path;
    },
    refresh() {
      let id = store.state.piste;
      let sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode,a.solde,a.idregulateur,r.nom AS nomregulateur,a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv,a.archive,a.dateecheance,IF(NOW()>a.dateecheance,1,0) as limite" + 
        ",(SELECT COUNT(*) FROM photoaction WHERE idaction=a.id)+IF(a.image<>'',1,0) as nbphoto" +
        " FROM actionpiste AS a" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN feu AS f ON f.id=a.idfeu" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN regulateur AS r ON r.id=a.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=a.idfonction" +
        " WHERE a.client=1 AND m.idpiste=" + id + " ORDER BY a.dt DESC";

      selectApi(this, sql).then(
        (response) => {
          this.counta = response.data.count;
          this.actions = response.data.result;
        },
        (response) => {
        }
      );

      sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id as idfeu,f.nom as nomfeu,c.strcode,a.idregulateur,r.nom AS nomregulateur,a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv,a.archive" +
        ",(SELECT COUNT(*) FROM photoaction WHERE idinter=a.id)+IF(a.image<>'',1,0) as nbphoto" +
        " FROM interpiste as a" +
        " LEFT JOIN maintenance as m ON m.id=a.idmaintenance" +
        " LEFT JOIN feu as f on f.id=a.idfeu" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN regulateur AS r ON r.id=a.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=a.idfonction" +
        " WHERE a.client=1 and m.idpiste=" + id + " ORDER BY a.dt DESC";
      selectApi(this, sql).then(
        (response) => {
          this.counti = response.data.count;
          this.interventions = response.data.result;
        },
        (response) => {
        }
      );
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

ul {
  list-style-type: none;
}

.limite {
  color: red;
}

.barre {
  text-decoration: line-through;
}
</style>